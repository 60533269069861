import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SplusBaseForm } from '@src/app/models/base/BaseFormComponent';
import { EmpleadosSettings } from "../config/empleados.settings";
import {
  ProcessType,
  SPlusDialogCloseResult,
  SPlusDialogSettings, 
  SPlusMoreOption,
  SPlusOption,
} from "@saludplus/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { Empleados } from "@src/app/models/Nomina/Empleados.model";
import { EmpleadosService } from "../service/empleados.service";
import { SelectEvent } from "@progress/kendo-angular-layout";
@Component({
  selector: "app-empleados",
  templateUrl: "./empleados.component.html",
  styleUrls: ["./empleados.component.scss"],
})
export class EmpleadosComponent extends SplusBaseForm implements OnInit, OnDestroy {

  // Forms
  override FormRecord: UntypedFormGroup;
  empleado: Empleados = new Empleados();
  DatosEmpleado = new Empleados();

  obtenerTiposDocumentosResult: any[] = [];
  obtenerSexosResult: any[] = [];

  //listado de opciones --
  options: SPlusOption[] = [
    {
      id: "Listado",
      icon: "fa-solid fa-magnifying-glass",
      text: "Listado",
      link: "/nomina/empleados/listado",
    },
    {
      id: "Ayuda",
      icon: "fa-solid fa-circle-question",
      text: "Ayuda",
      action: () => {
        this.OpenDocumentacion();
      },
    },
  ];

  //listado de más opciones --
  moreOptions: SPlusMoreOption[] = [
    {
      id: "CrearEmpleado",
      showIn: ProcessType.view,
      text: "Crear nuevo empleado",
      icon: "fa-duotone fa-plus",
      action: () => this.newRecord(),
    },
    {
      id: "EliminarEmpleado",
      showIn: ProcessType.edit,
      text: "Eliminar",
      icon: "fa-solid fa-trash-can",
      action: () => this.dialogEliminar(),
    },
  ];

  private unsubcribe$ = new Subject<void>();




  private service = inject(EmpleadosService);
  private settings = inject(EmpleadosSettings);


  numeroDocumento = "";
  searchingDocument = false;
  maxDate = new Date();
  nombreCompleto = "";

  constructor() {
    super()
  }


  hola() {
    console.log("hola");
  this.splusFormsStore.setProcessType(ProcessType.edit);
  }

  ngOnInit() {
    this.id = this.route.snapshot.params["id"];
    this.title = this.route.snapshot.data["title"];
    this.icon = this.route.snapshot.data["icon"];
    this.titleService.setTitle(this.title);

    this.FormRecord = this.settings.FormComponents();


    this.FormRecord.disable();

    this.ObtenerTiposDocumentos();
    this.ObtenerSexos();
    this.After();


    this.RuleTipoDocumento();
  }



  RuleTipoDocumento() {
    this.FormRecord.get("tipoDocumento")
      .valueChanges.pipe(takeUntil(this.unsubcribe$))
      .subscribe((value) => {
        if (value === "PE") {
          this.FormRecord.get("numeroDocumento").addValidators(
            Validators.maxLength(15)
          );
          this.FormRecord.get("numeroDocumento").updateValueAndValidity();
        } else {
          this.FormRecord.get("numeroDocumento").clearValidators();
          this.FormRecord.get("numeroDocumento").addValidators(Validators.required);
          this.FormRecord.get("numeroDocumento").updateValueAndValidity();
        }
      });
  }



  /**
   * Abreviacion para acceder a los controles del FormRecord
   */
  public get f(): any {
    return this.FormRecord.controls;
  }

  ngOnDestroy() {
    this.unsubcribe$.next();
    this.unsubcribe$.complete();
  }
  /**
   * Guardar o actualizar el registro
   */
  async onSubmit(event) {
    event.preventDefault();
    this.submitted = true;
    if (this.FormRecord.valid) {
      if (this.FormRecord.pristine || this.searchingDocument) return;
      this.isLoading = true;
      if (this.processType === this.ProcessType.edit) {
        this.UpdateRecord();
      } else {
        this.SaveRecord();
      }
    } else {
      this.isLoading = false;
    }
  }

  /**
   * Guardar
   */
  SaveRecord() {
    this.service
      .Guardar<number, Empleados>(this.FormRecord.value)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res) {
            this.router.navigate([`${res}`], {
              queryParams: { mode: "i" },
              relativeTo: this.route,
            });
          }

        }
      });
  }

  /**
   * Actualizar
   */
  UpdateRecord() {
    this.isLoading = true;
    this.submitted = true;
    if (this.FormRecord.valid && !this.FormRecord.pristine) {
      let data = this.FormRecord.value as Empleados;
      data.id = this.id;
      this.service
        .Actualizar<boolean, Empleados>(data)
        .subscribe({
          next: (res) => {
            this.isLoading = false;
            if (res) {
              this.router.navigate([], {
                queryParams: { mode: "u" },
                queryParamsHandling: "merge",
              });
            }


          }
        });
    } else {
      this.isLoading = false;
    }
  }
  /**
   * Eliminar
   */
  DeleteRecord() {
    this.isDeleting = true;
    this.isLoading = true;
    const userSearchParams = {
      idEmpleado: this.id
    };
    this.service.Eliminar<boolean>(userSearchParams).subscribe({
      next: (res) => {
        if (res) {
          this.isDeleting = false;
          this.router.navigate(["../"], {
            queryParams: { mode: "d" },
            queryParamsHandling: "merge",
            relativeTo: this.route,
          });
        }

      }
    });
  }

  /**
   * Abre alerta para eliminar registro
   */
  dialogEliminar() {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Eliminar empleado";
    dialogSettings.description =
      "¿Estas seguro que desea eliminar este empleado?";
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Si, Eliminar", themeColor: "warning", value: true },
    ];
    this.splusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
          console.log("close", result);
        } else {
          if (result.value) {
            this.DeleteRecord();
          }
        }
      });
  }

  /**
   * Limpiar formulario
   */
  clearForm() {
    this.FormRecord.reset();
    this.FormRecord.updateValueAndValidity();
    // Valores por defecto
    this.SetDefaultValues();
    this.submitted = false;
  }
  /**
   * Setea valores por defecto
   */
  SetDefaultValues() {
  }

  /**
   * formulario en modo edicción
   */
  goEdit() {
    this.routingState.goEdit();
  }

  /**
   * Cancelar insercion o edición
   */
  cancelEdit() {
    if (this.processType === ProcessType.create) {
      this.goback();
    } else if (this.processType === ProcessType.edit) {
      // validar si se modificó el formulario visualmente
      if (!this.FormRecord.pristine) {
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cancelar edición";
        dialogSettings.isHtml = true;
        dialogSettings.description =
          "Hay cambios pendientes por guardar. <br/>¿Estás seguro que desea cancelar la edición?";
        dialogSettings.actions = [
          { text: "Seguir Editando" },
          { text: "Si, Cancelar", themeColor: "primary", value: true },
        ];
        this.splusFormsDialogsService
          .Show(dialogSettings)
          .pipe(takeUntil(this.unsubcribe$))
          .subscribe((result) => {
            if (result instanceof SPlusDialogCloseResult) {
              console.log("close", result);
            } else {
              if (result.value) {
                this.cancelUpdate();
              }
            }
          });
      } else {
        this.goback();
      }
    }
  }



  /**
   * Cargar el registro por su id
   */
  LoadRecord() {

    this.numeroDocumento = "";
    this.isLoading = true;
    const userSearchParams = {
      idEmpleado: this.id
    };
    this.service.Buscar<Empleados>(userSearchParams).subscribe({
      next: (res) => {
        this.isDeleting = false;
        if (res) {
 

          res.fechaNacimiento = this.intl.parseDate(
            res.fechaNacimiento.toString(),
            ["G", "d"]
          );

          res.fechaIngreso = this.intl.parseDate(
            res.fechaIngreso.toString(),
            ["G", "d"]
          );
          
          this.DatosEmpleado = res;
          if (this.DatosEmpleado) {
            this.numeroDocumento = this.DatosEmpleado.numeroDocumento;

            this.DatosEmpleado.email = this.DatosEmpleado.email
              ? this.DatosEmpleado.email.trim()
              : null;
            this.FormRecord.patchValue(this.DatosEmpleado);
            this.isLoading = false;
            this.nombreCompleto =
              this.FormRecord.value.primerNombre +
              " " +
              (this.FormRecord.value.segundoNombre
                ? this.FormRecord.value.segundoNombre
                : "") +
              " " +
              this.FormRecord.value.primerApellido +
              " " +
              (this.FormRecord.value.segundoApellido
                ? this.FormRecord.value.segundoApellido
                : "");
          }
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico de lado del cliente", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cargar empleado";
        dialogSettings.description =
          "Hubo un error al intentar cargar el empleado por favor intentelo más tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.splusFormsDialogsService.Show(dialogSettings);
      },
    });
  }

  /**
   * Cargar datos ObtenerTiposDocumentos
   */
  ObtenerTiposDocumentos() {
    this.service.ObtenerTiposDocumentos<any>().subscribe({
      next: (res) => {
        if (res) this.obtenerTiposDocumentosResult = res;
      },
      error: (err) => this.handleError(err, "ObtenerTiposDocumentos")
    });
  }

  /**
  * Cargar datos ObtenerSexos
  */
  ObtenerSexos() {
    this.service.ObtenerSexos<any>().subscribe({
      next: (res) => {
        if (res) {
          this.obtenerSexosResult = res;
        }
      },
      error: (err) => this.handleError(err, "ObtenerSexos")
    });
  }


  After() {
    this.FormRecord.enable();

    if (this.id) {
      this.LoadRecord();
    } else {
      this.SetDefaultValues();
    }
    this.isLoading = false;
  }
 

  /**
   * Buscar registro por su numero de documento
   */
  buscarRegistroPorDocumento() {
    let documento = this.f.numeroDocumento.value;

    if (documento === "" || documento === this.numeroDocumento) {
      return;
    }

    let tipoDocumento = this.f.tipoDocumento.value;
    if (!tipoDocumento) {
      return;
    }

    this.searchingDocument = true;

    this.DatosEmpleado = new Empleados();
    const userSearchParams = {
      numeroDocumento: documento,
      tipoDocumento: tipoDocumento,
    };

    this.service.ValidarExistencia<Empleados>(userSearchParams).subscribe({
      next: (res) => {
        this.searchingDocument = false;
        if (res) {
          let dialogSettings = {} as SPlusDialogSettings;
          dialogSettings.title = "Empleado Encontrado";
          dialogSettings.description = `El tipo de documento  ${tipoDocumento} y número de documento ${documento} ya existe.`;
          dialogSettings.actions = [
            { text: "Cancelar" },
            { text: "Ver empleado", themeColor: "primary", value: true },
          ];
          this.splusFormsDialogsService
            .Show(dialogSettings)
            .pipe(takeUntil(this.unsubcribe$))
            .subscribe((result) => {
              if (result instanceof SPlusDialogCloseResult || !result.value) {
                this.searchingDocument = false;
                this.isLoading = false;
                if (this.route.routeConfig.path !== ":id") {
                  this.FormRecord.controls["numeroDocumento"].setValue("");
                } else {
                  this.FormRecord.controls["numeroDocumento"].setValue(
                    this.numeroDocumento
                  );
                }
              } else {
                if (result.value) {
                  if (this.route.routeConfig.path === ":id") {
                    this.processType = this.ProcessType.view;
                    this.id = res.id;
                    this.LoadRecord();
                    this.router.navigate([
                      "nomina/empleados/" + this.id,
                    ]);
                    this.searchingDocument = false;
                  } else {
                    this.processType = this.ProcessType.view;
                    this.searchingDocument = false;
                    this.router.navigate([res.id], {
                      relativeTo: this.route,
                    });
                  }
                }
              }
            });
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Error al consultar el empleado";
        dialogSettings.description =
          "Hubo un error al intentar consultar el empleado por favor intentelo más tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.splusFormsDialogsService.Show(dialogSettings);
        this.isLoading = false;
        this.searchingDocument = false;
      },
    });

    //}
  }

  tabSeleted = 0;
  public onTabSelect(e: SelectEvent): void {
    this.tabSeleted = e.index;
  }


  /**
   * Cancelar edicion
   */
  cancelUpdate() {
    this.FormRecord.patchValue(this.DatosEmpleado);
    this.FormRecord.markAsPristine();
    this.router.navigate([], {
      queryParams: { mode: null },
      queryParamsHandling: "merge",
    });
  }


}
