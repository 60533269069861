<splus-forms id="empleadosPage" [submit]="submitted" [messageProcess]="messageProcess"
    [showMessageProcess]="showMessageProcess" [FormRecord]="FormRecord">

    <form [formGroup]="FormRecord" (ngSubmit)="onSubmit($event)" autocomplete="off"
        [ngClass]="{'formGroup-read': processType == ProcessType.view}">
        <splus-forms-header [isLoading]="isLoading">
            <splus-forms-main-title [title]="'Empleados'"
                [titleData]="processType == ProcessType.view ? nombreCompleto : 'Empleados'" [icon]="icon">
            </splus-forms-main-title>

            <div *ngIf="processType == ProcessType.view || processType == ProcessType.edit">
                <div class="sub-title-info ">
                    {{(this.FormRecord.value.primerNombre + ' '+ (this.FormRecord.value.segundoNombre ?
                    this.FormRecord.value.segundoNombre : '') + ' ' + this.FormRecord.value.primerApellido + ' ' +
                    (this.FormRecord.value.segundoApellido ? this.FormRecord.value.segundoApellido
                    : '')) | uppercase }}

                    <div *ngIf="isLoading">

                        <div class="k-hbox">
                            <kendo-skeleton shape="text" animation="pulse" width="100px"></kendo-skeleton> &nbsp;
                            <kendo-skeleton shape="text" animation="pulse" width="70px"></kendo-skeleton> &nbsp;
                            <kendo-skeleton shape="text" animation="pulse" width="90px"></kendo-skeleton>
                        </div>
                    </div>


                </div>
            </div>


            <splus-forms-toolbar [options]="options">
                <splus-forms-toolbar-buttons [moreOptions]="moreOptions" [processType]="processType">

                    <button type="Submit" [disabled]="isLoading"
                        *ngIf="processType == ProcessType.create || processType == ProcessType.edit" kendoButton
                        rounded="small" themeColor="primary">
                        <i class="fa-solid fa-floppy-disk"></i> Guardar
                    </button>

                    <button [disabled]="isLoading" type="button" *ngIf="processType == ProcessType.view" kendoButton
                        rounded="small" themeColor="primary" (click)="goEdit()">
                        <i class="fa-solid fa-pencil"></i> Editar
                    </button>

                    <button *ngIf="processType == ProcessType.edit" type="button" kendoButton rounded="small"
                        themeColor="base" (click)="cancelEdit()">
                        Cancelar
                    </button>

                    <button *ngIf="processType == ProcessType.view" type="button" kendoButton rounded="small"
                        themeColor="base" (click)="goback()">
                        Volver
                    </button>

                    <button *ngIf="processType == ProcessType.create" type="button" kendoButton rounded="small"
                        themeColor="base" (click)="clearForm()">
                        Limpiar
                    </button>

                </splus-forms-toolbar-buttons>
            </splus-forms-toolbar>


        </splus-forms-header>

        <splus-forms-fields [processType]="processType">

            <splus-forms-group title="Información Personal" #grupoBasico>
                <splus-field #numeroDocumento *ngVar="f.numeroDocumento as ctrl" [splusFormControl]="ctrl">
                    <kendo-textbox splusInput [splusField]="numeroDocumento" [splusGroup]="grupoBasico" type="text"
                        [id]="ctrl.nameControl" [formControl]="ctrl" (blur)="buscarRegistroPorDocumento()">

                        <ng-template kendoTextBoxPrefixTemplate>
                            <div class="m-1">
                                <i [ngClass]="icon"></i>
                            </div>
                            <kendo-textbox-separator></kendo-textbox-separator>
                        </ng-template>

                        <ng-template kendoTextBoxSuffixTemplate>
                            <div *ngIf="searchingDocument" class="k-i-loading w-5">&nbsp;</div>
                        </ng-template>

                    </kendo-textbox>

                </splus-field>

                <splus-field *ngVar="f.tipoDocumento as ctrl" [splusFormControl]="ctrl" #tipoDocumento>
                    <kendo-dropdownlist #select splusInput [splusField]="tipoDocumento"
                        (valueChange)="buscarRegistroPorDocumento()" [splusGroup]="grupoBasico" [formControl]="ctrl"
                        [data]="obtenerTiposDocumentosResult" [textField]="'nombre'" [valueField]="'valor'"
                        [valuePrimitive]="true">
                    </kendo-dropdownlist>

                    <ng-template splusFieldViewModeTemplate>
                        {{select["text"]}}
                    </ng-template>
                </splus-field>

                <splus-field *ngVar="f.primerNombre as ctrl" [splusFormControl]="ctrl" >
                    <kendo-textbox splusInput [splusGroup]="grupoBasico" type="text" [id]="ctrl.nameControl"
                        [formControl]="ctrl"> </kendo-textbox>
                </splus-field>

                <splus-field *ngVar="f.segundoNombre as ctrl" [splusFormControl]="ctrl">
                    <kendo-textbox splusInput [splusGroup]="grupoBasico" type="text" [id]="ctrl.nameControl"
                        [formControl]="ctrl"> </kendo-textbox>
                </splus-field>

                <splus-field *ngVar="f.primerApellido as ctrl" [splusFormControl]="ctrl">
                    <kendo-textbox splusInput [splusGroup]="grupoBasico" type="text" [id]="ctrl.nameControl"
                        [formControl]="ctrl"> </kendo-textbox>
                </splus-field>

                <splus-field *ngVar="f.segundoApellido as ctrl" [splusFormControl]="ctrl">
                    <kendo-textbox splusInput [splusGroup]="grupoBasico" type="text" [id]="ctrl.nameControl"
                        [formControl]="ctrl"> </kendo-textbox>
                </splus-field>

                <splus-field *ngVar="f.fechaNacimiento as ctrl" [splusFormControl]="ctrl" #fechaNacimiento>

                    <kendo-datepicker #calendar splusInput [navigation]="true" [max]="maxDate" format="dd/MM/yyyy"
                        [formControl]="ctrl" [splusGroup]="grupoBasico" [splusField]="fechaNacimiento"
                        [value]="ctrl.value">

                        <ng-template kendoCalendarNavigationItemTemplate let-title>
                            {{isNaN(title) ? title : " Ene. " + title.substring( 2, 4 )}}
                        </ng-template>

                    </kendo-datepicker>

                    <ng-template splusFieldViewModeTemplate>
                        {{calendar.value | SPlusDate}}
                    </ng-template>

                    <div class="hit">
                        {{!calendar.value ? '' : calendar.value | InfoDateFormat}}
                    </div>


                </splus-field>

                <splus-field *ngVar="f.idSexo as ctrl" [splusFormControl]="ctrl">
                    <kendo-dropdownlist #select splusInput [splusGroup]="grupoBasico" [formControl]="ctrl"
                        [data]="ctrl?.funtionData | async" [textField]="ctrl?.dataTextField"
                        [valueField]="ctrl?.dataValueField" [valuePrimitive]="true">
                    </kendo-dropdownlist>

                    <ng-template splusFieldViewModeTemplate>
                        {{select["text"]}}
                    </ng-template>
                </splus-field>

                <splus-field *ngVar="f.idEstadoCivil as ctrl" [splusFormControl]="ctrl">
                    <kendo-dropdownlist #select splusInput [splusGroup]="grupoBasico" [formControl]="ctrl"
                        [data]="ctrl?.funtionData | async" [textField]="ctrl?.dataTextField"
                        [valueField]="ctrl?.dataValueField" [valuePrimitive]="true">
                    </kendo-dropdownlist>

                    <ng-template splusFieldViewModeTemplate>
                        {{select["text"]}}
                    </ng-template>
                </splus-field>


            </splus-forms-group>

            <splus-forms-group title="Contacto" #grupoContacto>

                <splus-field *ngVar="f.direccion as ctrl" [splusFormControl]="ctrl">
                    <kendo-textbox splusInput [splusGroup]="grupoContacto" type="text" [id]="ctrl.nameControl"
                        [formControl]="ctrl"> </kendo-textbox>
                </splus-field>

                <splus-field *ngVar="f.telefono as ctrl" [splusFormControl]="ctrl">
                    <kendo-numerictextbox splusInput [splusGroup]="grupoContacto" [id]="ctrl.nameControl"
                        [spinners]="false" format="#" [formControl]="ctrl"></kendo-numerictextbox>
                </splus-field>

                <splus-field *ngVar="f.email as ctrl" [splusFormControl]="ctrl">
                    <kendo-textbox splusInput [splusGroup]="grupoContacto" type="text" [id]="ctrl.nameControl"
                        [formControl]="ctrl">

                        <ng-template kendoTextBoxPrefixTemplate>
                            <div class="m-1">
                                <i class="fa-solid fa-envelope opacity-70"></i>
                            </div>
                            <kendo-textbox-separator></kendo-textbox-separator>
                        </ng-template>

                    </kendo-textbox>
                </splus-field>

            </splus-forms-group>


            <splus-forms-group title="Información Laboral" #grupoContratacion>


                <splus-field *ngVar="f.idTipoEmpleado as ctrl" [splusFormControl]="ctrl">
                    <kendo-dropdownlist #select splusInput [splusGroup]="grupoBasico" [formControl]="ctrl"
                        [data]="ctrl?.funtionData | async" [textField]="ctrl?.dataTextField"
                        [valueField]="ctrl?.dataValueField" [valuePrimitive]="true">
                    </kendo-dropdownlist>

                    <ng-template splusFieldViewModeTemplate>
                        {{select["text"]}}
                    </ng-template>
                </splus-field>


                <splus-field *ngVar="f.fechaIngreso as ctrl" [splusFormControl]="ctrl" #fechaIngreso>
                    <kendo-datepicker #calendar splusInput [navigation]="true" format="dd/MM/yyyy" [formControl]="ctrl"
                        [splusGroup]="grupoContratacion" [splusField]="fechaIngreso" [value]="ctrl.value">

                        <ng-template kendoCalendarNavigationItemTemplate let-title>
                            {{isNaN(title) ? title : " Ene. " + title.substring( 2, 4 )}}
                        </ng-template>

                    </kendo-datepicker>

                    <ng-template splusFieldViewModeTemplate>
                        {{calendar.value | SPlusDate}}
                    </ng-template>

                    <div class="hit">
                        {{!calendar.value ? '' : calendar.value | InfoDateFormat}}
                    </div>
                </splus-field>


                <splus-field class="ml-2 flex items-center min-h-14" *ngVar="f.estado as ctrl" [splusFormControl]="ctrl"
                    [viewModeDisable]="true" labelCustom="">

                    <input #ctrlCheck type="checkbox" [readonly]="true" rounded="small" splusInput kendoCheckBox
                        [formControl]="ctrl" [splusGroup]="grupoContratacion" [id]="ctrl.nameControl" />

                    <ng-template splusFieldViewModeTemplate>
                        <splus-checkbox-info [checked]="ctrlCheck?.checked">
                        </splus-checkbox-info>
                    </ng-template>

                    <label [for]="processType != ProcessType.view ? ctrl.nameControl : ''" class="select-none text-slate-500 dark:text-slate-400 text-sm">
                        {{ctrl?.label}}</label>

                </splus-field>


            </splus-forms-group>


            <splus-tabs (tabSelect)="onTabSelect($event)" [keepTabContent]="true">
                <splus-tab [selected]="true" *ngIf="processType != ProcessType.create && DatosEmpleado.id">

                    <ng-template splusTabTitle>
                        <span class="select-none"> <i class=" text-nomina fa-duotone fa-solid fa-memo-pad mr-1"></i>
                            Contrato</span>
                    </ng-template>

                    <ng-template splusTabContent>
                        <app-tabContratos [idContrato]="DatosEmpleado.idContratoVigente" [datosEmpleado]="DatosEmpleado"
                            [processType]="processType"></app-tabContratos>
                    </ng-template>
                </splus-tab>

                <splus-tab *ngIf="processType != ProcessType.create && DatosEmpleado.id">

                    <ng-template splusTabTitle>
                        <span class="select-none"> <i class="text-nomina fa-solid fa-file-lines mr-1"></i>
                            Documentos</span>
                    </ng-template>

                    <ng-template splusTabContent>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos a non explicabo dicta quisquam hic,
                        laboriosam iure dolore, assumenda, porro illo cumque unde! Distinctio maiores vitae debitis
                        impedit ex error.
                    </ng-template>
                </splus-tab>

                <splus-tab *ngIf="processType != ProcessType.create && DatosEmpleado.id">

                    <ng-template splusTabTitle>
                        <span class="select-none"> <i class="text-nomina fa-duotone fa-solid fa-abacus mr-1"></i>
                            Novedades</span>
                    </ng-template>
                    <ng-template splusTabContent>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos a non explicabo dicta quisquam hic,
                        laboriosam iure dolore, assumenda, porro illo cumque unde! Distinctio maiores vitae debitis
                        impedit ex error.
                    </ng-template>
                </splus-tab>


                <splus-tab *ngIf="processType != ProcessType.create && DatosEmpleado.id">

                    <ng-template splusTabTitle>
                        <span class="select-none"> <i class=" text-nomina fa-regular fa-paperclip-vertical mr-1"></i>
                            Archivos</span>
                    </ng-template>

                    <ng-template splusTabContent>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos a non explicabo dicta quisquam hic,
                    </ng-template>
                </splus-tab>




            </splus-tabs>

        </splus-forms-fields>

    </form>
</splus-forms>